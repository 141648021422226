import { DateField, Show, ShowProps, SimpleShowLayout, TextField } from 'react-admin';


export const SingleModeTasksShow = (props: ShowProps) => {

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source='id' />
                <TextField source='type' />
                <TextField source='title' />
                <TextField source='actionTitle' />
                <TextField source='actionLink' />
                <TextField source='reward' />
                <TextField source='state' />
                <DateField source='createdAt' />
                <DateField source='updatedAt' />
            </SimpleShowLayout>
        </Show>
    );
};
