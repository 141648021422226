import { Edit, EditProps, NumberInput, regex, required, SelectInput, SimpleForm, TextInput } from 'react-admin';

export const SingleModeTasksEdit = (props: EditProps) => {

  return (
    <Edit {...props} mutationMode={'pessimistic'}>
      <SimpleForm warnWhenUnsavedChanges>
        <TextInput disabled source='id' />
        <SelectInput source='type'
                     validate={[required()]}
                     optionText={'label'} optionValue={'value'}
                     choices={[
                       { value: 'OPEN_LINK', label: 'OPEN_LINK' },
                       { value: 'FOLLOW_TELEGRAM_CHANNEL', label: 'FOLLOW_TELEGRAM_CHANNEL' },
                     ]}
        />
        <TextInput source='title' validate={[required()]} />
        <TextInput source='actionTitle' validate={[required()]} />
        <TextInput source='actionLink' validate={[required(), regex(/^https:\/\//)]} />
        <NumberInput source='reward' validate={[required()]} />
        <SelectInput source='state'
                     validate={[required()]}
                     optionText={'label'} optionValue={'value'}
                     choices={[
                       { value: 'ACTIVE', label: 'ACTIVE' },
                       { value: 'DISABLED', label: 'DISABLED' },
                     ]}
        />
      </SimpleForm>
    </Edit>
  );
};
