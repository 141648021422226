import {
  AutocompleteInput,
  DateInput,
  ReferenceInput, required,
  SaveButton,
  SimpleForm, TextInput,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { Grid } from '@mui/material';
import EngineeringIcon from '@mui/icons-material/Engineering';

const message = `🚀 Earn Up to *17,500* Points Just by Visiting the App!

🎁 Bonus LOL Points for *Starting a Game*: Hit "Play" and receive bonus points instantly. Want even more points? Just smile!

📸 Having camera issues? Simply refresh the page!`;

const BotNotificationSender = function() {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const createNotification = (formData: any) => {
    dataProvider.sendRequest('POST', 'bot-notifications/single-reminder', {
      date: formData.date,
      receiverIds: formData.receiverIds || [],
      message: formData.message,
    })
      .then((res: any) => {
        notify(`Reminder Job Successfully set`, { type: 'success' });
      })
      .catch((err: any) => {
        notify(`Reminder Job setting failed ${err.message}`, { type: 'error' });
      });
  };

  function submitForm(formData: any) {
    createNotification(formData);
  }

  return (
    <>
      <h3>Create Bot Notification</h3>

      <Grid container style={{ width: '100%' }}>
        <SimpleForm onSubmit={submitForm} width={600}
                    toolbar={<SaveButton label={'Create Reminder'} icon={<EngineeringIcon />} />}>

          <DateInput source={'date'} label={'Users inactive after'} validate={required()} fullWidth />
          <ReferenceInput label='Receiver' source='receiverIds' reference='users' fullWidth>
            <AutocompleteInput filterToQuery={(searchText => ({ search: searchText }))} fullWidth
                               multiple
                               optionText={(r) => [r.email, r.telegramId].filter(v => !!v).join(' - ')}
                               optionValue={'id'} />
          </ReferenceInput>
          <TextInput source="message" validate={required()} rows={10} multiline fullWidth
                     defaultValue={message}
          />

        </SimpleForm>
      </Grid>
    </>
  );
};

export default BotNotificationSender;
