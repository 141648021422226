import { Create, NumberInput, regex, required, SimpleForm, TextInput, SelectInput } from 'react-admin';
import { CreateProps } from 'ra-ui-materialui/dist/cjs/types';

export const SingleModeTasksCreate = (props: CreateProps) => {
    return (
        <Create {...props}>
            <SimpleForm warnWhenUnsavedChanges>
                <SelectInput source='type'
                             validate={[required()]}
                             optionText={'label'} optionValue={'value'}
                             choices={[
                                 { value: 'OPEN_LINK', label: 'OPEN_LINK' },
                                 { value: 'FOLLOW_TELEGRAM_CHANNEL', label: 'FOLLOW_TELEGRAM_CHANNEL' },
                               ]}
                />
                <TextInput source='title' validate={[required()]} />
                <TextInput source='actionTitle' validate={[required()]} />
                <TextInput source='actionLink' validate={[required(), regex(/^https:\/\//)]} />
                <NumberInput source='reward' validate={[required()]} />
            </SimpleForm>
        </Create>
    );
};
